import React, { useState, useCallback, useMemo } from "react";
import ImageViewer from "react-simple-image-viewer";

export const Gallery = (props) => {
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const data = useMemo(() => [
    { thumb: "img/portfolio/01-small.jpg", title: "ERP Control WEB", link: "https://erpcontrol.fusaotecno.com/" },
    { thumb: "img/portfolio/02-small.jpg", title: "ERP Control Dektop", link: "https://erpcontroldesktop.fusaotecno.com/" },
    { thumb: "img/portfolio/03-small.jpg", title: "Delivery Fusão Control", link: "https://fusaotecno.com/" },
  ], []);

  const images = useMemo(() => data.map((obj) => obj.thumb.replace("-small")), [data]);

  const openImageViewer = useCallback(
    (index) => {
      const item = data[index];

      if (item.link) {
        window.open(item.link, "_blank");
      } else {
        setCurrentImage(index);
        setIsViewerOpen(true);
      }
    },
    [data]
  );

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  return (
    <div id="portfolio" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Sistemas</h2>
          <p>Conheça nossos Sistemas Integrados <br /> <br />

<p className="text-left">Desenvolvemos soluções completas e inovadoras para atender às necessidades específicas do seu negócio. 
Nossos sistemas são projetados para otimizar processos, aumentar a eficiência e impulsionar o crescimento da sua empresa.
São desenvolvidos para atender às necessidades específicas do seu negócio, proporcionando maior controle, eficiência e 
competitividade.</p></p>
        </div>
        <div className="row">
          <div className="portfolio-items">
            {data.map(({ title, thumb }, index) => (
              <div
                key={index}
                onClick={() => openImageViewer(index)}
                className="col-sm-6 col-md-4 col-lg-4"
              >
                <div className="portfolio-item cursor">
                  <div className="hover-bg">
                    <div className="hover-text">
                      <h4>{title}</h4>
                    </div>
                    <img
                      src={thumb}
                      className="img-responsive"
                      alt={title}
                    />{" "}
                  </div>
                </div>
              </div>
            ))}
          </div>

          {isViewerOpen && (
            <ImageViewer
              src={images}
              currentIndex={currentImage}
              onClose={closeImageViewer}
            />
          )}
        </div>
      </div>
    </div>
  );
};