import React from "react";

export const Team = (props) => {
  return (
    <div id="team" className="text-center">
      <div className="container">
        <div className="col-md-8 col-md-offset-2 section-title">
          <h2>NOSSO TIME</h2>
          <p className="text-left">
          Especialistas em Tecnologia comprometidos com o seu sucesso.
          <br />
          <br />
Nosso maior trunfo é o nosso time excepcional. Somos uma equipe dedicada de profissionais de tecnologia 
que compartilha uma paixão pela inovação e um compromisso inabalável com o sucesso de nossos clientes.
<br />
<br />

Aqui está um olhar mais profundo sobre o que torna nosso time tão excepcional:
<br />
<br />
EXPERIÊNCIA DIVERSIFICADA: NOSSA EQUIPE É COMPOSTA POR INDIVÍDUOS TALENTOSOS COM EXPERIÊNCIA DIVERSIFICADA EM UMA 
VARIEDADE DE ÁREAS TECNOLÓGICAS. DESDE DESENVOLVEDORES DE SOFTWARE EXPERIENTES ATÉ ESPECIALISTAS EM SEGURANÇA CIBERNÉTICA, 
CIENTISTAS DE DADOS E DESIGNERS DE UX/UI, TEMOS UMA EQUIPE MULTIDISCIPLINAR QUE PODE ABORDAR DESAFIOS COMPLEXOS COM UMA PERSPECTIVA ABRANGENTE. <br />
APRENDIZADO CONTÍNUO: NO MUNDO EM CONSTANTE EVOLUÇÃO DA TECNOLOGIA, A APRENDIZAGEM CONTÍNUA É ESSENCIAL. NOSSA EQUIPE ESTÁ COMPROMETIDA EM 
MANTER-SE ATUALIZADA COM AS ÚLTIMAS TENDÊNCIAS E AVANÇOS EM SUAS RESPECTIVAS ÁREAS. PARTICIPAMOS REGULARMENTE DE TREINAMENTOS E CONFERÊNCIAS 
PARA GARANTIR QUE NOSSOS CONHECIMENTOS ESTEJAM SEMPRE ATUALIZADOS. <br />
COLABORAÇÃO E COMUNICAÇÃO: ACREDITAMOS QUE A COMUNICAÇÃO TRANSPARENTE E A COLABORAÇÃO SÃO FUNDAMENTAIS PARA O SUCESSO DE QUALQUER PROJETO. 
NOSSA EQUIPE TRABALHA EM ESTREITA COLABORAÇÃO COM NOSSOS CLIENTES, MANTENDO CANAIS ABERTOS DE COMUNICAÇÃO PARA GARANTIR QUE SUAS NECESSIDADES 
E EXPECTATIVAS SEJAM COMPREENDIDAS E ATENDIDAS. <br />
SOLUÇÕES CRIATIVAS: ENCORAJAMOS A CRIATIVIDADE EM NOSSA EQUIPE, ACREDITANDO QUE AS MELHORES SOLUÇÕES MUITAS VEZES VÊM DE ABORDAGENS INOVADORAS. 
ESTIMULAMOS O PENSAMENTO CRIATIVO E A BUSCA POR SOLUÇÕES QUE ULTRAPASSEM AS BARREIRAS CONVENCIONAIS. <br />
COMPROMISSO COM A QUALIDADE: NOSSA EQUIPE ESTÁ FOCADA EM MANTER OS MAIS ALTOS PADRÕES DE QUALIDADE EM TODOS OS NOSSOS PROJETOS. 
IMPLEMENTAMOS RIGOROSOS PROCESSOS DE CONTROLE DE QUALIDADE PARA GARANTIR QUE CADA PRODUTO OU SERVIÇO QUE ENTREGAMOS SEJA CONFIÁVEL, SEGURO E EFICAZ. <br />
PAIXÃO PELO CLIENTE: NÓS VIVEMOS PARA O SUCESSO DE NOSSOS CLIENTES. COLOCAMOS OS INTERESSES DE NOSSOS CLIENTES EM PRIMEIRO LUGAR E ESTAMOS 
DEDICADOS A FORNECER SOLUÇÕES QUE REALMENTE ATENDAM ÀS SUAS NECESSIDADES E OBJETIVOS. <br />
RESOLUÇÃO DE PROBLEMAS: NOSSO TIME É COMPOSTO POR SOLUCIONADORES DE PROBLEMAS ÁVIDOS. ADORAMOS DESAFIOS TÉCNICOS E ESTAMOS MOTIVADOS A ENCONTRAR 
SOLUÇÕES PARA OS PROBLEMAS MAIS COMPLEXOS. <br />
DIVERSIDADE E INCLUSÃO: ACREDITAMOS QUE A DIVERSIDADE FORTALECE NOSSA EQUIPE E ENRIQUECE NOSSAS PERSPECTIVAS. VALORIZAMOS A INCLUSÃO E A IGUALDADE EM 
NOSSA EMPRESA. <br /> <br />
Nosso time não é apenas uma equipe, somos profissionais e especialistas apaixonados por tecnologia que trabalham juntos para alcançar resultados 
excepcionais. Nossos talentos individuais se unem para formar uma força coletiva que impulsiona o sucesso de nossos clientes. Estamos ansiosos 
para fazer parte da sua jornada tecnológica e ajudá-lo a alcançar novos patamares de excelência. Junte-se a nós e descubra como nossa 
equipe pode transformar sua visão em realidade.
          </p>
        </div>
        <div id="row">
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className="col-md-3 col-sm-6 team">
                  <div className="thumbnail">
                    {" "}
                    <img src={d.img} alt="..." className="team-img" />
                    <div className="caption">
                      <h4>{d.name}</h4>
                      <p>{d.job}</p>
                    </div>
                  </div>
                </div>
              ))
            : "loading"}
        </div>
      </div>
    </div>
  );
};
